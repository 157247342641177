import { Stack } from "@mui/material";
import { axios } from "../Axios/axiosInstance";
import { useEffect, useRef, useState } from "react";
import {
  Cicon,
  Link,
  Loading,
  Typography,
  theme,
} from "@imago-cloud/design-system";
import { useRecoilValue } from "recoil";
import { authAtom } from "../Atoms";

const USER_API = "account/users/me";
const Profile = () => {
  const { isAuth, isLoading: isAuthLoading } = useRecoilValue(authAtom);

  const [{ email, fullName, profileImage }, setUser] = useState({
    email: "",
    fullName: "",
    profileImage: "",
  });
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const didFetchRef = useRef(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsFetchLoading(true);
        const { firstName, lastName, profileImage } = await axios
          .get(USER_API)
          .then((res) => res.data.data);

        setUser(
          {
            fullName: `${firstName} ${lastName}`,
            email: "",
            profileImage,
          } || {}
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (!didFetchRef.current) {
      fetchUser();
      didFetchRef.current = true;
    }
  }, []);

  return (
    <Stack
      sx={{
        width: "540px",
        height: "92px",
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.common.white,
        borderRadius: "12px",
        padding: "20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isAuth ? (
        <>
          {isFetchLoading ? (
            <Loading size="24" type="basic" />
          ) : (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              {profileImage ? (
                <Stack
                  component={"img"}
                  sx={{ width: "48px", height: "48px", borderRadius: "50%" }}
                  src={profileImage}
                  crossOrigin="anonymous"
                />
              ) : (
                <Stack
                  sx={{
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.grey[300],
                  }}
                />
              )}

              <Stack>
                <Typography variant="H18">{fullName}</Typography>
                <Typography variant="Body16">{email}</Typography>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <>
          {isAuthLoading ? (
            <Loading size="24" type="basic" />
          ) : (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "8px" }}
            >
              <Cicon
                icon="Warning"
                svgProps={{ width: "20px", height: "20px" }}
              />

              <Typography variant="H18">
                <Link
                  variant="H18"
                  color="gray_dark"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    fontWeight: 700,
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.open(process.env.REACT_APP_ACCOUNTS_URL);
                  }}
                >
                  Login
                </Link>{" "}
                is required
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default Profile;
