import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  theme,
} from "@imago-cloud/design-system";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Controller, useFormContext } from "react-hook-form";
const MENU_LIST = [
  {
    label: "Viewer",
    value: "viewer",
    fields: [
      {
        label: "designCaseId",
        name: "viewer_designCaseId",
        form: { type: "textfield" },
      },
    ],
  },
  {
    label: "Export",
    value: "export",
    fields: [
      {
        label: "designCaseIds",
        name: "export_designCaseIds",
        form: { type: "textfield" },
      },
      {
        label: "exactHost",
        name: "export_exactHost",
        form: {
          type: "select",
          options: ["none", "explorerModule", "batch", "importTo"],
        },
      },
      {
        label: "importToId",
        name: "export_importToId",
        form: { type: "textfield" },
      },
    ],
  },
  {
    label: "Setting",
    value: "setting",
    fields: [
      {
        label: "defaultMenu",
        name: "setting_defaultMenu",
        form: {
          type: "select",
          options: ["none", "system", "storage", "export", "network"],
        },
      },
    ],
  },
  {
    label: "Explorer",
    value: "explorer",
    fields: [
      {
        label: "designCaseId",
        name: "explorer_designCaseId",
        form: { type: "textfield" },
      },
      {
        label: "type",
        name: "explorer_type",
        form: {
          type: "select",
          options: ["none", "importer", "opener"],
        },
      },
      {
        label: "integration",
        name: "explorer_integration",
        form: {
          type: "select",
          options: ["none", "meditLink", "shining3d"],
        },
      },
    ],
  },
];
const Configuration = () => {
  const [menu, setMenu] = useState(MENU_LIST[0].value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookies] = useCookies();
  const {
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useFormContext();
  const onSubmit = (data: any) => {
    setCookies("moduleTestbed", data, { path: "/" });
    reset(data);
  };
  return (
    <Stack
      sx={{
        width: "540px",
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.common.white,
        borderRadius: "12px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "68px",
          padding: "24px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderBottomColor: theme.palette.divider,
        }}
      >
        <Typography variant="H18">Configuration</Typography>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          flexDirection: "row",
        }}
      >
        <Stack
          sx={{
            minWidth: "160px",
            maxHeight: "360px",
            overflowY: "auto",
            borderRight: "1px solid",
            borderRightColor: theme.palette.divider,
            padding: "16px",
            gap: "4px",
          }}
        >
          {MENU_LIST.map(({ label, value }) => {
            return (
              <MenuItem
                key={value}
                value={value}
                size="34"
                sx={{ ...theme.typography.H14 }}
                selected={value === menu}
                onClick={() => {
                  setMenu(value);
                }}
              >
                {label}
              </MenuItem>
            );
          })}
        </Stack>
        <Stack
          sx={{
            width: "100%",
            padding: "16px",
            gap: "8px",
            maxHeight: "180px",
            overflowY: "auto",
          }}
        >
          {MENU_LIST.find(({ value }) => value === menu)?.fields.map(
            ({ label, name, form }) => {
              const { type, options } = form || {};
              switch (type) {
                case "textfield":
                  return (
                    <Stack sx={{ gap: "4px" }} key={name}>
                      <Typography variant="H14">{label}</Typography>
                      <Controller
                        render={({ field: { onChange, value, ...others } }) => (
                          <TextField
                            size="36"
                            onChange={onChange}
                            sx={{ width: "100%" }}
                            value={value || ""}
                            {...others}
                          />
                        )}
                        name={name}
                      />
                    </Stack>
                  );
                case "select":
                  return (
                    <Stack sx={{ gap: "4px" }} key={name}>
                      <Typography variant="H14">{label}</Typography>
                      <Controller
                        render={({
                          field: { onChange, ref, value, ...others },
                        }) => (
                          <Select
                            SelectProps={{
                              MenuProps: {
                                sx: {
                                  ".MuiPaper-root": {
                                    padding: "4px",
                                    borderRadius: "8px",
                                    maxHeight: "216px",
                                  },
                                  ".MuiList-root": {
                                    padding: "0px",
                                  },
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                            }}
                            size="36"
                            onChange={onChange}
                            inputRef={ref}
                            value={value || ""}
                            {...others}
                          >
                            {(options || []).map((value) => (
                              <MenuItem
                                key={value}
                                value={value}
                                size="34"
                                sx={{
                                  minHeight: "34px !important",
                                }}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name={name}
                      />
                    </Stack>
                  );
                default:
                  return null;
              }
            }
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderTop: "1px solid",
          borderTopColor: theme.palette.divider,
          padding: "16px",
        }}
      >
        <Button
          size="36"
          color="primary"
          variant="contained"
          sx={{ minWidth: "100px" }}
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

export default Configuration;
