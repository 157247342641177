import { theme as ITheme, ThemeProvider } from "@imago-cloud/design-system";
import { createTheme } from "@mui/material";

export const IThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const Theme = () => {
    return createTheme({
      ...ITheme,
      components: {
        ...ITheme.components,
        MuiInputBase: {
          defaultProps: {
            autoComplete: "off",
          },
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1280,
          lg: 1920,
          xl: 2560,
        },
      },
    });
  };
  return <ThemeProvider theme={Theme()}>{children}</ThemeProvider>;
};
