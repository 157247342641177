import { setRecoil } from "recoil-nexus";
import { Axios } from ".";
import axios_default from "axios";
import { authAtom } from "../Atoms";

const POST_CASE_REFRESH_TOKEN_ISSUANCE_API = "account/auth/token/refresh";
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "";

const unAuthorizedInstance = axios_default.create({
  baseURL: BACKEND_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const requestAccessToken = async () => {
  try {
    setRecoil(authAtom, (prev) => ({ ...prev, isLoading: true }));
    const { data: responseData } = await unAuthorizedInstance.patch(
      POST_CASE_REFRESH_TOKEN_ISSUANCE_API
    );

    const { accessToken } = responseData.data;
    if (typeof accessToken !== "string") {
      console.error("accessToken is not string");
      return null;
    }
    setRecoil(authAtom, (prev) => ({
      ...prev,
      isAuth: true,
      isLoading: false,
    }));
    return { accessToken };
  } catch (err) {
    console.error(err, "refresh token expired");
  } finally {
    setRecoil(authAtom, (prev) => ({ ...prev, isLoading: false }));
  }
};

export const axios = Axios.createAxiosInstance({
  baseURL: BACKEND_BASE_URL,
  requestAccessToken: requestAccessToken,
});
