import {
  useModuleContext,
  IExportModuleProps,
  IExplorerModuleProps,
  IViewerModuleProps,
} from "@imago/imago-modules";
import { ModuleItem } from "../Components/ModuleItem";
import { useFormContext } from "react-hook-form";

export const ExportModuleButton = () => {
  const {
    export: { onOpen },
  } = useModuleContext();
  const { watch } = useFormContext();
  const initialData = {
    designCaseIds: watch("export_designCaseIds")?.split(",") || [],
    exactHost:
      watch("export_exactHost") === "none" || !watch("export_exactHost")
        ? undefined
        : (watch(
            "export_exactHost"
          ) as IExportModuleProps["initialData"]["exactHost"]),
    importToId: watch("export_importToId"),
  };
  return (
    <ModuleItem
      title="Export Module"
      onClick={() =>
        onOpen({
          initialData: initialData,
          onSuccess: (res: any) => {
            console.log(res);
          },
          onError: () => {
            window.location.reload();
          },
        })
      }
    />
  );
};

export const SettingModuleButton = () => {
  const {
    setting: { onOpen },
  } = useModuleContext();
  const { watch } = useFormContext();
  const initialData = {
    defaultMenu:
      watch("setting_defaultMenu") === "none" || !watch("setting_defaultMenu")
        ? undefined
        : watch("setting_defaultMenu"),
  };
  return (
    <ModuleItem
      title="Setting Module"
      onClick={() => onOpen({ initialData: initialData })}
    />
  );
};

export const ExplorerModuleButton = () => {
  const {
    explorer: { onOpen },
  } = useModuleContext();
  const { watch } = useFormContext();
  const initialData = {
    designCaseId: watch("explorer_designCaseId"),
    type:
      watch("explorer_type") === "none" || !watch("explorer_type")
        ? undefined
        : watch("explorer_type"),
    integration:
      watch("explorer_integration") === "none" || !watch("explorer_integration")
        ? undefined
        : watch("explorer_integration"),
  };
  return (
    <>
      <ModuleItem
        title="Explorer Module"
        onClick={() =>
          onOpen({
            initialData:
              initialData as unknown as IExplorerModuleProps["initialData"],
          })
        }
      />
    </>
  );
};

export const ViewerModuleButton = () => {
  const {
    viewer: { onOpen },
  } = useModuleContext();

  const { watch } = useFormContext();
  const initialData = {
    designCaseId: watch("viewer_designCaseId"),
  };

  return (
    <ModuleItem
      title="Viewer Module"
      onClick={() =>
        onOpen({
          initialData: initialData as IViewerModuleProps["initialData"],
        })
      }
    />
  );
};
