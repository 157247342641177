type Environment = "dev" | "qa" | "guest" | "prod" | "cbt";

export const getEnvironment = (): Environment => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if (
    env === "dev" ||
    env === "qa" ||
    env === "guest" ||
    env === "prod" ||
    env === "cbt"
  ) {
    return env;
  }
  console.error("Invalid environment value");
  return "dev";
};
