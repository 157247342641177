import { Button, Typography } from "@imago-cloud/design-system";
import { useRecoilValue } from "recoil";
import { authAtom } from "../Atoms";
export const ModuleItem = ({
  title,
  disabled,
  onClick,
}: {
  title: string;
  disabled?: boolean;
  onClick: () => void;
}) => {
  const { isAuth } = useRecoilValue(authAtom);
  return (
    <Button
      variant="outlined"
      color="grey"
      disabled={!isAuth || disabled}
      sx={{
        cursor: "pointer",
        width: "100%",
        height: "68px",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "18px 32px 18px 20px",
        "> *": {
          pointerEvents: "none",
          userSelect: "none",
        },
      }}
      onClick={onClick}
    >
      <Typography variant="Button_Bold20">{title}</Typography>
    </Button>
  );
};
