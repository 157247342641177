import { theme } from "@imago-cloud/design-system";
import Profile from "./Components/Profile";
import { Stack } from "@mui/material";
import { ModuleSelect } from "./Components/ModuleSelect";
import Configuration from "./Components/Configuration";
import { FormProvider, useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
function App() {
  const methods = useForm({
    defaultValues: {
      designCaseId: "",
    },
  });
  const { reset } = methods;
  const [cookies] = useCookies(["moduleTestbed"]);

  useEffect(() => {
    const { moduleTestbed } = cookies || { moduleTestbed: "" };
    reset(moduleTestbed);
  }, [cookies, reset]);

  return (
    <FormProvider {...methods}>
      <Stack
        sx={{
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.canvas,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Profile />
          <Configuration />
          <ModuleSelect />
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default App;
