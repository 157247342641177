import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { IThemeProvider } from "./IthemeProvider";
import { getEnvironment } from "./Utils";
import { ModuleProvider } from "@imago/imago-modules";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <IThemeProvider>
        <ModuleProvider
          config={{
            env: getEnvironment(),
          }}
        >
          <App />
        </ModuleProvider>
      </IThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);
