import { Stack } from "@mui/material";
import { Chip, theme, Typography } from "@imago-cloud/design-system";
import {
  ExportModuleButton,
  SettingModuleButton,
  ExplorerModuleButton,
  ViewerModuleButton,
} from "../Modules";
export const ModuleSelect = () => {
  return (
    <Stack
      sx={{
        width: "540px",
        minHeight: "30px",
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.common.white,
        borderRadius: "12px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "68px",
          padding: "24px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="H18">Select module client</Typography>
        <Chip
          label={`배포환경: ${(
            process.env.REACT_APP_ENVIRONMENT ?? ""
          ).toUpperCase()}`}
          variant="outlined"
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          padding: "0px 24px 24px 24px",
          gap: "12px",
        }}
      >
        <ViewerModuleButton />
        <ExportModuleButton />
        <SettingModuleButton />
        <ExplorerModuleButton />
      </Stack>
    </Stack>
  );
};
